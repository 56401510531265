<template>
  <v-card class="my-3">
    <v-card-title> Fontes </v-card-title>
    <v-card-text>
      <v-form ref="sourcesForm" @submit.prevent="add">
        <v-text-field
          label="Adicionar fonte de alimento"
          hint="Pressione ENTER para adicionar"
          v-model="sourceToAdd"
        />
        <div class="mt-3">
          <template v-if="sources.length > 0">
            <v-chip
              v-for="(source, index) in sources"
              :key="index"
              close
              @click:close="remove(index)"
              class="mr-1 mb-1"
            >
              {{ source.name }}
            </v-chip>
          </template>
          <template v-else>
            <p class="font-italic">Nenhuma fonte de alimento adicionada.</p>
          </template>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "SourcesForm",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    sources: [],
    sourceToAdd: "",
  }),
  watch: {
    value(value) {
      if (value) {
        this.sources = value;
      }
    },
  },
  mounted() {
    if (this.value) {
      this.sources = this.value;
    }
  },
  methods: {
    add() {
      if (this.sourceToAdd) {
        this.sources.push({
          name: this.sourceToAdd,
        });
        this.$emit("input", this.sources);
        this.sourceToAdd = "";
      }
    },
    remove(index) {
      this.sources.splice(index, 1);
      this.$emit("input", this.sources);
    },
  },
};
</script>

<style scoped></style>
